import React from "react";
import Container from "reactstrap/lib/Container";
import {StationStorage} from "../../library/storage/Storage";
import StationenEnum from "../../types/StationenEnum";
import {Alert, Button, ButtonGroup} from "reactstrap";
import StationSpecialAbstract from "./StationSpecialAbstract";
import StationSpecial0 from "./special/StationSpecial0/StationSpecial0";
import StationSpecial1 from "./special/StationSpecial1/StationSpecial1";
import StationSpecial2 from "./special/StationSpecial2/StationSpecial2";
import StationSpecial3 from "./special/StationSpecial3/StationSpecial3";
import StationSpecial5 from "./special/StationSpecial5/StationSpecial5";
import StationSpecial4 from "./special/StationSpecial4/StationSpecial4";
import StationSpecial6 from "./special/StationSpecial6/StationSpecial6";
import StationSpecial7 from "./special/StationSpecial7/StationSpecial7";
import StationSpecial8 from "./special/StationSpecial8/StationSpecial8";
import StationSpecial9 from "./special/StationSpecial9/StationSpecial9";
import StationSpecial10 from "./special/StationSpecial10/StationSpecial10";
import StationSpecial11 from "./special/StationSpecial11/StationSpecial11";
import StationSpecial12 from "./special/StationSpecial12/StationSpecial12";
import StationSpecial13 from "./special/StationSpecial13/StationSpecial13";
import StationSpecial14 from "./special/StationSpecial14/StationSpecial14";
import StationSpecial15 from "./special/StationSpecial15/StationSpecial15";
import StationSpecial16 from "./special/StationSpecial16/StationSpecial16";
import StationSpecial17 from "./special/StationSpecial17/StationSpecial17";
import StationSpecial18 from "./special/StationSpecial18/StationSpecial18";
import AlertModal from "../../components/AlertModal";

type stateType = {
    station: StationenEnum;
    succeded: boolean
}

export default class Station extends React.Component <{}, stateType> {
    STATIONS = [StationSpecial0, StationSpecial1, StationSpecial2, StationSpecial3, StationSpecial4, StationSpecial5,
        StationSpecial6, StationSpecial7, StationSpecial8, StationSpecial9, StationSpecial10, StationSpecial11,
        StationSpecial12, StationSpecial13, StationSpecial14, StationSpecial15, StationSpecial16, StationSpecial17, StationSpecial18]
        // @ts-ignore
        .map((station: typeof StationSpecialAbstract) => React.createElement(station, {onStationSucceeded: (succeded = true) => this.setState({succeded})}));

    constructor(props: any) {
        super(props);
        this.state = {
            station: StationStorage.getStation() || 0,
            succeded: false
        };
        this.onNext = this.onNext.bind(this);
        this.onSkip = this.onSkip.bind(this);
    }

    onNext() {
        const station = this.state.station + 1;
        this.setState({station, succeded: false});
        StationStorage.setStation(station);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    onSkip() {
        AlertModal.show({
            header: "Station überspringen",
            message: "Willst du die Station wirklich überspringen? Es kann sein, dass du durch das Überspringen die Rally nicht mehr richtig verstehst. Mache das wirklich nur wenn du wirklich nicht weiter kommst!",
            buttons: [{
                text: "Ja, überspringen",
                color: "danger",
                handler: () => AlertModal.show({
                    header: "Station überspringen",
                    message: "Bist du ganz sicher?",
                    buttons: [{
                        text: "Ja, ich bin sicher!",
                        color: "danger",
                        handler: this.onNext
                    }, {
                        text: "Nein, ich versuchs doch lieber nochmal",
                        color: "success"
                    }]
                })
            }, {
                text: "Nein, ich versuchs nochmal",
                color: "success"
            }]
        })
    }

    render() {
        const station = this.STATIONS[this.state.station];
        return (<Container className="my-2">
            <div className="my-3">
                {station}
            </div>
            <div className="text-center" style={{display: this.state.succeded ? undefined : "none"}}>
                <Alert color="success" className="m-auto">
                    Du hast diese Station beendet, gehe jetzt weiter zur nächsten!
                </Alert>
            </div>
            <div className="text-right mt-2"
                 style={{display: this.state.station < this.STATIONS.length - 1 ? undefined : "none"}}>
                <ButtonGroup>
                    <Button onClick={this.onSkip}>
                        Überspringen
                    </Button>
                    <Button color="primary" onClick={this.onNext} disabled={!this.state.succeded}>
                        Nächste Station
                    </Button>
                </ButtonGroup>
            </div>
        </Container>);
    }
}
