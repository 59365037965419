import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import Error from "./Error";
import Page from "./Page";
import Home from "./home/Home";
import StationNew from "./station/StationNew";
import Station from "./station/Station";


export default class Router extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={`/home`} render={(props) => <Page><Home/></Page>}/>
                    <Route path={`/station/new`} render={(props) => <Page><StationNew/></Page>}/>
                    <Route path={`/station`} render={(props) => <Page><Station/></Page>}/>
                    <Route exact path={`/`}>
                        <Redirect to={`/home`}/>
                    </Route>
                    <Route exact path='*' render={(props) => <Page><Error/></Page>}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
