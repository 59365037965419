import React from 'react';
import {Button} from 'reactstrap'
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class Error extends React.Component {

    render() {
        if (!window.location.toString().endsWith(`${process.env.PUBLIC_URL}/error`)) {
            window.location.replace(`${process.env.PUBLIC_URL}/error`);
            return <div></div>;
        } else
            return (
                <div>
                    <div style={{marginTop: "1em", marginBottom: "1em"}} className="container">
                        <h1>Error</h1>
                        <p>Die Seite, die du suchst, existiert nicht!</p>
                        <Button color="primary" href={`${process.env.PUBLIC_URL}/`}>
                            <FontAwesomeIcon icon={faHome} className="mr-2"/>
                            Zurück zur Startseite
                        </Button>
                    </div>
                </div>
            );
    }

}
