import React, {CSSProperties} from "react";
import ReactCardFlip from 'react-card-flip';
import pantomime from "../resources/pantomime.svg";

type propType = {
    className?: string,
    word: string
}

type stateType = {
    flipped: boolean
}

const cardStyles: CSSProperties = {
    height: "20em",
    width: "15em",
    backgroundColor: "var(--dark)",
    borderRadius: "2em",
    color: "white",
    border: "5px solid var(--primary)",
    padding: "11px",
    margin: "auto",
    cursor: "pointer"
}

export default class PantomimeCard extends React.Component<propType, stateType> {
    constructor(props: propType) {
        super(props);
        this.state = {
            flipped: false
        };
        this.flip = this.flip.bind(this);
    }

    flip() {
        this.setState({flipped: !this.state.flipped});
    }

    render() {
        return (<div className={`text-center ${this.props.className}`}>
            <ReactCardFlip isFlipped={this.state.flipped}>
                <div style={cardStyles} onClick={this.flip}>
                    <h2>Pantomime</h2>
                    <img src={pantomime} alt="pantomime" className="mt-3" style={{width: "10em"}}/>
                </div>
                <div style={cardStyles} onClick={this.flip}>
                    <h2>Pantomime</h2>
                    <h3 className="mb-0 mt-5">Begriff</h3>
                    <div className="mt-4">
                        <b style={{fontSize: "2em", lineHeight: "1em"}}>{this.props.word}</b>
                    </div>
                </div>
            </ReactCardFlip>
        </div>);
    }

}
