import React from 'react';
import Router from "./pages/Router";

export default class App extends React.Component {
    render() {
        return (
            <Router/>
        );
    }
}

