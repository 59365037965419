import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Alert, Button, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checker from "../../../../library/Checker";
import locationImg from "./Station2Location.jpg";

type stateType = {
    value: string,
    showError: boolean
}

export default class StationSpecial2 extends StationSpecialAbstract<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: "",
            showError: false
        };
        this.check = this.check.bind(this);
    }

    check() {
        if (parseInt(this.state.value) === 6 || parseFloat(this.state.value) === 5.5) {
            this.props.onStationSucceeded();
            this.setState({showError: false});
        } else {
            this.props.onStationSucceeded(false);
            this.setState({showError: true});
        }
    }

    render() {
        return (<div>
            <h2>2. Zebrastreifen zählen</h2>
            <Location name="Zebrastreifen in der Nähe von St. Lioba" noLink={true} image={locationImg}/>
            Ihr habt es geschafft. Zählt die Zebrastreifen! Wie viele sind es?
            <FormGroup className="text-center my-3">
                <InputGroup className="m-auto" style={{width: "15em"}}>
                    <Input type="number" value={this.state.value} onKeyUp={e => Checker.isEnter(e, this.check)}
                           onChange={e => this.setState({value: e.target.value})}/>
                    <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={this.check}>
                            <FontAwesomeIcon icon={faUserCheck} className="mr-2"/>Überprüfen
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <Alert color="danger" className="text-center" style={{display: this.state.showError ? undefined : "none"}}
            toggle={() => this.setState({showError: false})}>
                Die Anzahl der weißen Streifen auf dem Zebrastreifen sind leider nicht korrekt!
                <br/>
                Versuche es nochmal!
            </Alert>
        </div>);
    }
}
