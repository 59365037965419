import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station15Location.jpg";

export default class StationSpecial15 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>15. Frühstücks-Trinkpause und Putzen & Packen</h2>
            <Location name={`49°48'12.9"N 9°58'53.7"E`} image={locationImg}
            text="Die Flürleinstraße ist lang. Lauft sie weiter, bis es nicht mehr geht. Hier ist eure nächste Station."/>
            <p>
                „Jede Zelle meines Körpers ist glücklich. Jede Körperzelle fühlt sich wohl...“ <span role="img" aria-label="Musik">🎶</span>
                <br />
                Guten Moooorgen! 
                Es ist mal wieder Zeit für eine Trinkpause.
                Außerdem müssen wir jetzt Putzen und Packen. Wir spielen ein Spiel, es heißt „Kategorie“.  
                <h3>Spielanleitung</h3>
                Die Kategorie in dieser Runde heißt „Putzen“. Der Reihe nach sagt jeder Mitspieler ein Wort, das zum Thema „Putzen“ passt. Dafür hat er immer 5 Sekunden Zeit. Fällt einem Spieler kein Wort mehr ein und die 5 Sekunden sind abgelaufen, ist er raus und die restlichen Spieler spielen weiter, bis ein Sieger feststeht. 
                <br />
                Beispiel mit der Kategorie „Ministrieren“ 
                <br />
                Spieler A: Gewänder – Spieler B: Leuchter – A: Kreuz – B: Beten – A: Sakristei – B fällt nichts mehr ein <FontAwesomeIcon icon={faArrowRight} className="mr-2"/> Somit hat A gewonnen.
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Fertig
                </Button>
            </div>
        </div>);
    }
}
