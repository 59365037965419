import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";

export default class StationSpecial1 extends StationSpecialAbstract {

    render() {
        return (<div>
            <h2>1. Los geht's aufs WiWo</h2>
            <Location name="Zebrastreifen in der Nähe von St. Lioba" noLink={true}/>
            <p>
                Die Fahrt beginnt! Geht zum nächstgelegenen Zebrastreifen (in der Pilziggrundstraße). 
                Eure Aufgabe auf dem Weg dorthin:
                Eine Person schließt die Augen und lässt sich von einer zweiten Person blind lenken.
                Wenn ihr aus unterschiedlichen Hausständen seid, zieht eure Mund-Nasen-Bedeckung auf.
                Macht dabei laute Motorgeräusche <span role="img" aria-label="zwinkern">😉</span> Passt auf die Autos auf! Viel Spaß!
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">Angekommen!</Button>
            </div>
        </div>);
    }
}
