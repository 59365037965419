export default class LocationStorage {

    static invertShowLocation(){
        LocationStorage.setShowLocation(!LocationStorage.getShowLocation());
    }

    static setShowLocation(location : boolean) {
        window.localStorage.setItem("location", location.toString());
    }

    static getShowLocation(): boolean {
        const location =  window.localStorage.getItem("location");
        if  (!location)
            return true;
        else
            return location === "true";
    }

    static removeShowLocation(){
        window.localStorage.removeItem("location");
    }
}
