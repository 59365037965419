import React from "react";
import {Badge, Button, Collapse} from "reactstrap";
import {faArrowRight, faChevronDown, faChevronUp, faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LocationStorage} from "../library/storage/Storage";
import {isMobile} from 'react-device-detect';

type propType = {
    coordinates?: string,
    name: string,
    noLink?: boolean,
    text?: string,
    image?: string
}


export default class Location extends React.Component<propType> {
    render() {
        let url = "";
        if (!this.props.noLink){
            if (isMobile)
                url = `geo:0,0?q=${this.props.coordinates || this.props.name}`;
            else
                url = `https://www.google.com/maps/place/${this.props.coordinates || this.props.name}`;
        }

        return (<div style={{border: "1px solid var(--secondary)", borderRadius: 10, padding: 5}} className="mb-2">
            <div className="float-right mx-1 pointer"
                 style={{display: this.props.text || this.props.image ? undefined : "none"}}
                 onClick={() => {
                     LocationStorage.invertShowLocation();
                     this.forceUpdate()
                 }}>
                <FontAwesomeIcon style={{fontSize: "1em"}}
                                 icon={LocationStorage.getShowLocation() ? faChevronUp : faChevronDown}/>
            </div>
            <Badge style={{fontSize: "1em"}} className="mr-2">Ort</Badge>
            <Button color="primary" className="mr-2" size="sm" outline
                    onClick={() => window.navigator.clipboard.writeText(this.props.coordinates || this.props.name)}>
                <FontAwesomeIcon icon={faCopy}/>
            </Button>
            {this.props.noLink ?
                <span style={{fontWeight: "bold", fontSize: "1em"}}>{this.props.name}</span>
                :
                <a href={url}
                   target="_blank" rel="noopener noreferrer" style={{fontWeight: "bold", fontSize: "1em"}}>
                    {this.props.name}
                </a>
            }
            <Collapse isOpen={(!!this.props.text || !!this.props.image) && LocationStorage.getShowLocation()}
                      className="text-center">
                <div style={{display: this.props.text ? undefined : "none"}}>{this.props.text}</div>
                <img src={this.props.image} alt="Wegbeschreibung"
                     style={{display: this.props.image ? undefined : "none", maxWidth: "100%"}}/>
                <div style={{display: this.props.image ? undefined : "none"}}>
                    <span style={{color: "SeaGreen"}}>Standort</span>
                    <FontAwesomeIcon icon={faArrowRight} className="mx-2"/>
                    <span style={{color: "red"}}>Ziel</span>
                </div>
            </Collapse>
        </div>);
    }
}
