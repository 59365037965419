import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Alert, Button, ButtonGroup} from "reactstrap";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station6Location.jpg";

type stateType = {
    status: "undefined" | "success" | "error"
}

export default class StationSpecial6 extends StationSpecialAbstract<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            status: "undefined"
        };
        this.setSuccess = this.setSuccess.bind(this);
    }

    setSuccess(yes: boolean) {
        if (!yes) {
            this.setState({status: "success"});
            this.props.onStationSucceeded();
        } else {
            this.setState({status: "error"});
            this.props.onStationSucceeded(false);
        }
    }

    render() {
        return (<div>
            <h2>6. Nachtwanderung</h2>
            <Location name={`49°47'55.9"N 9°59'12.3"E`} image={locationImg}
            text="Nun geht´s zum Bolzi! Findet ihr ihn? Nach der großen Hundewiese geht der Schotterweg nach rechts weiter. Folgt diesem! Bevor ihr auf die geteerte Straße kommt, geht ein Stichweg nach links weg. Dort versteckt sich der Bolzplatz."/>
            <p>
                Um noch ein bisschen Frische Luft zu schnappen, machen wir eine kleine Nachtwanderung. Es ist schon 20.30 Uhr. Dürfen wir da eigentlich noch auf den Bolzplatz?
            </p>
            <div className="text-center">
            <ButtonGroup>
                <Button color="success" onClick={() => this.setSuccess(true)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Ja
                </Button>
                <Button color="danger" onClick={() => this.setSuccess(false)}>
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2"/>Nein
                </Button>
            </ButtonGroup>
            </div>
            <Alert color="danger" className="text-center mt-3" isOpen={this.state.status === "error"}
                   toggle={() => this.setState({status: "undefined"})}>
                Leider nicht die richtige Antwort.
                <br/>
                Versuche es nochmal!
            </Alert>
        </div>);
    }
}
