import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station9Location.jpg";

export default class StationSpecial9 extends StationSpecialAbstract {

    render() {
        return (<div>
            <h2>9. Workshops</h2>
            <Location name={`49°48'07.9"N 9°59'40.0"E`} image={locationImg}
            text="Geht den Weg weiter. Am Ende des Feldweges noch vor den Glascontainern ist schon wieder die nächste Station."/>
            <p>
                Nach dem Aufstehen gibts natürlich erstmal Frühstück!
                Ihr könnt also jetzt erstmal eine Pause machen und euch mit euerem mitgebrachten Essen stärken.
            </p>
            <p>
                Danach gehts weiter mit dem Kreativworkshop.
                Diesen führt ihr später zu Hause durch.
                Dafür findet ihr in eurem Tütchen eine Anleitung und die Materialien für eine Eisbärgeschenktüte.
                Also hebt die Tüte noch komplett auf und bewahrt sie gut, sodass nichts kaputt geht. Viel Spaß beim Basteln später.
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Okay
                </Button>
            </div>
        </div>);
    }
}
