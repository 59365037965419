import React from "react";

export default class Checker {
    /**
     * Was enter pressed?
     * @param {React.KeyboardEvent} e Event that was triggered by keypress
     * @param {() => void} callback will be trigger if event is triggered by enter
     * @returns {boolean} if enter was pressed
     */
    public static isEnter(e: React.KeyboardEvent, callback: () => void): boolean {
        if (e.keyCode === 13 || e.which === 13) {
            callback();
            return true;
        } else {
            return false;
        }
    }
}
