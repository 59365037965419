import StationEnum from "../../types/StationenEnum";

export default class StationStorage {

    static setStation(station : StationEnum) {
        window.localStorage.setItem("station", station.toString());
    }

    static getStation(): StationEnum | null {
        const station =  window.localStorage.getItem("station");
        if  (!station)
            return null;
        else
            return parseInt(station) as StationEnum;
    }

    static removeStation(){
        window.localStorage.removeItem("station");
    }
}
