import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Alert, Button, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checker from "../../../../library/Checker";
import locationImg from "./Station8Location.jpg";

type stateType = {
    value: string,
    showError: boolean
}

export default class StationSpecial8 extends StationSpecialAbstract<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: "",
            showError: false
        };
        this.check = this.check.bind(this);
    }

    check() {
        if (parseInt(this.state.value) === 16) {
            this.props.onStationSucceeded();
            this.setState({showError: false});
        } else {
            this.props.onStationSucceeded(false);
            this.setState({showError: true});
        }
    }

    render() {
        return (<div>
            <h2>8. Schäfchen zählen</h2>
            <Location name={`49°48'01.2"N 9°59'40.8"E`} image={locationImg}
            text="Geht geradeaus weiter. Jetzt wird´s kniffelig: Nach einiger Zeit beginnt auf der linken Seite ein Feld. Auf dem Feld befinden sich zwei Strommaste. Zwischen den zwei Masten geht ein kleiner Feldweg durch. Den nehmt ihr! Seht ihr auf der rechten Seite eine geteerte Einfahrt, seid ihr zu weit gegangen. Die Station führt ihr bei den Masten durch."/>
            Jetzt ist es aber endgültig Zeit für´s Bett. Zum Einschlafen zählt man normalerweise Schäfchen. Wir zählen etwas anderes. Wie viele Kabel hängen an den Strommasten in der Näheren Umgebung? (Tipp: Es gibt neben den beiden Strommasten an deinem Standort noch einen weiteren)
            <FormGroup className="text-center my-3">
                <InputGroup className="m-auto" style={{width: "15em"}}>
                    <Input type="number" value={this.state.value} onKeyUp={e => Checker.isEnter(e, this.check)}
                           onChange={e => this.setState({value: e.target.value})}/>
                    <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={this.check}>
                            <FontAwesomeIcon icon={faUserCheck} className="mr-2"/>Überprüfen
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <Alert color="danger" className="text-center" style={{display: this.state.showError ? undefined : "none"}}
            toggle={() => this.setState({showError: false})}>
                Das ist leider nicht die richtie Antwort, versuche es erneut!
                <br/>
                Versuche es nochmal!
            </Alert>
        </div>);
    }
}
