import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button, Collapse} from "reactstrap";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AlertModal from "../../../../components/AlertModal";
import locationImg from "./Station7Location.jpg";

type stateType = {
    showSolution: boolean,
}

export default class StationSpecial5 extends StationSpecialAbstract<{}, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            showSolution: false
        };
        this.showSolution = this.showSolution.bind(this);
    }

    showSolution() {
        AlertModal.show({
            header: "Lösung anzeigen",
            subHeader: "Willst du wirklich die Lösung anzeigen?",
            message: "Hast du die Blackstory schon beantwortet und willst wirklich die Lösung anzeigen?",
            buttons: [{
                color: "primary",
                text: "Ja",
                handler: () => this.setState({showSolution: true}, () => this.props.onStationSucceeded())
            }, {
                color: "primary",
                text: "Nein"
            }]
        })
    }

    render() {
        return (<div>
            <h2>7. Gute-Nacht-BlackStory</h2>
            <Location name={`49°47'57.7"N 9°59'22.9"E`} image={locationImg}
            text="Station erledigt? Dann verabschiedet euch vom Bolzi und nehmt den Weg nach rechts wieder zurück. An der Kreuzung nehmt ihr den Feldweg nach rechts. Nach kurzer Zeit trefft ihr auf eine geschotterte Kreuzung. Macht dort die nächste Station."/>
            <p>
                Bevor ihr ins Bett geht, müsst ihr nochmal euer Gehirn anstrengen und gut überlegen, damit ihr die
                knifflige Aufgabe löst.
                In eurem Tütchen findet ihr ein zusammengerolltes Papier, dieses wird geöffnet.
                Alternativ findet ihr auch die Blackstory hier weiter unten.
                Jetzt die Köpfe anschalten und das Raten der Black – Story kann beginnen.
                Währenddessen dürft ihr schon weiterlaufen.
                Die Lösung erfahrt ihr noch im Laufe der Rallye.
            </p>
            <h3>Spielanleitung</h3>
            <p>
               Eine Person liest die Blackstory laut vor.
               Danach liest er sich die Lösung <b>leise</b> durch.
               Nun stellen die anderen Mitspieler Fragen zu der vorgelesenen Geschichte, um auf die Lösung zu kommen.
               Die Fragen dürfen nur mit Ja oder Nein beantwortet werden.
            </p>
            <h3>Blackstory</h3>
            <p>
                Einige Personen sind heute zu einem romantischen Dinner in ein ausgezeichnetes Sternerestaurant gegangen
                und haben ein leckeres 4-Gängemenü verspeist.
                Es war grandios, die Bäuche sind voll und alle sind satt geworden.
                Doch plötzlich musste sich ein Großteil der Gäste übergeben.
                <br/>
                <b>War etwas schlecht im Menü oder warum übergaben sich so viele?</b>
                <br/>
                Wir erfuhren, dass mit dem Essen allerdings alles in Ordnung war.
                <br/>
                Was war dann geschehen?
            </p>
            <div className="text-right mt-2">
                <Button color="primary"
                        onClick={this.state.showSolution ? () => this.setState({showSolution: false}) : this.showSolution}>
                    <FontAwesomeIcon icon={faSearch} className="mr-2"/>
                    Lösung {this.state.showSolution ? "verbergen" : "anzeigen"}
                </Button>
            </div>
            <Collapse isOpen={this.state.showSolution}>
                <h3>Lösung</h3>
                <i>
                    Das Restaurant befindet sich auf einem Kreuzfahrtschiff.
                    Nach dem Galadinner begann ein heftiger Sturm und viele der Gäste wurden Seekrank und mussten
                    sich daraufhin übergeben.
                </i>
            </Collapse>
        </div>);
    }
}
