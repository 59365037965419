import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station13Location.jpg";

export default class StationSpecial13 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>13. Bunter Abend: Teekesselchen</h2>
            <Location name={`49°48'18.0"N 9°59'17.8"E`} image={locationImg}
            text="Habt ihr euch gemerkt, wie es weitergeht? Genau, nach links, in Richtung des Kreisels in der Stauferstraße. Am Kreisel angekommen, findet ihr die Bushaltestelle „Stauferstraße“. Daneben ist ein Fußweg, der mit dem Schild öffentliche Grünanlage gekennzeichnet ist. Biegt in diesen ab. Bevor der Weg wieder geteert ist, sucht ihr euch ein schönes Plätzchen für die Station."/>
            Unser letzter Abend ist angebrochen und natürlich – wie immer – ein Bunter Abend.
            Wir spielen Teekesselchen.
            <h3>Spielanleitung</h3>
            <p>
                Spieler A beschreibt ein Wort, dass zwei Bedeutungen hat, auf einen Zettel. Anschließend beschreibt er es auf zwei verschiedene Arten. Spieler B muss das Wort erraten.
                <br />
                <br />
                Beispiel: Lösungswort Schloss
                <br />
                Spieler A sagt: „Mein Teekesselchen kommt in vielen Märchen vor und sichert mein Fahrrad.“
                <br />
                Spieler B errät: Schloss (Märchenschloss und Fahrradschloss) 
                <br />
                Jetzt seid ihr an der Reihe. Denkt euch selbst Teekesselchen aus und stellt sie eurem Partner. Ihr könnt auch folgende Teekesselchen nehmen:
                <ul>
                    <li>Strauß (Vogelstrauß und Blumenstrauß)</li>
                    <li>Note (Musiknote & Schulnote)</li>
                    <li>Bank (Sitzgelegenheit & Ort zum Geldabheben)</li>
                    <li>Hahn (Tier & Wasserhahn)</li>
                </ul>
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Fertig
                </Button>
            </div>
        </div>);
    }
}
