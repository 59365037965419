import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import QRReader from "../../../../components/QRReader";

export default class StationSpecial0 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>Treffpunkt Lioba</h2>
            <Location name={`Schaukasten der Minis St. Lioba (49°48'07.5"N 9°58'54.2"E)`}
                      coordinates={`49°48'07.5"N 9°58'54.2"E`}
                      text="Den Schaukasten der Minis von St. Lioba, das solltest du doch kennen! Wenn nicht? - Er befindet sich links neben der Treppe zum Liobahöfle."/>
            <p>
                Jetzt gehts los!
                Bist du an Lioba und hast alles dabei?
                <br/>
                Dann scanne den QR-Code im Schaukasten, um fortzufahren!
            </p>
            <QRReader onStationSucceeded={this.props.onStationSucceeded} qr="https://www.minis-lioba.de"/>
        </div>);
    }
}
