import React from 'react';
import "./Footer.scss"

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer box-shadow text-center">
                <span> &copy; {new Date().getFullYear()} <a target="_blank" rel="noopener noreferrer" href={`https://www.felix-franz.com`}>Felix Franz</a> & <a target="_blank" rel="noopener noreferrer" href={`https://www.minis-lioba.de`}>Minis St. Lioba</a>
                </span>
            </footer>
        );
    }
}
