enum StationenEnum {
    Station0,
    Station1,
    Station2,
    Station3,
    Station4,
    Station5,
    Station6,
    Station7,
    Station8,
    Station9,
    Station10,
    Station11,
    Station12,
    Station13,
    Station14,
    Station15,
    Station16,
}

export default StationenEnum;
