import React from 'react';
import {Button, ButtonGroup, Container, Jumbotron} from "reactstrap";
import {faMap, faMapMarked} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StationStorage} from "../../library/storage/Storage";

type stateType = {
    offline: boolean,
}

export default class Home extends React.Component<any, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            offline: true
        }
    }

    async componentDidMount() {
        this.setState({offline: false})
    }

    render() {
        return (
            <Container className="my-2">
                <Jumbotron style={{padding: "2rem"}}>
                    <h2>Herzlich Willkommen bei unserem Winterwochenend- Alternativprogramm 2021</h2>
                    <p>
                        Los geht´s aufs Winterwochenende!
                        Schön, dass ihr dabei seid.
                        Hoffentlich habt ihr alles dabei: Einen Stift, das Tütchen, eine Picknickdecke oder Sitzgelegenheit, Trinken und vielleicht einen kleinen Snack. 
                    </p>
                    <p>
                        Ihr kommt nicht weiter oder habt Fragen?
                        Dann überspringt die Station einfach mit dem Button <i>überspringen</i> oder schreibt einfach eine Mail an <a
                            href="mailto:rally@minis-lioba.de?subject=Ich%20brauche%20Hilfe!%20%7C%20Mini-Rally&body=Bitte%20schreibe%20hier%20worum%20es%20geht!"
                        >rally@minis-lioba.de</a>!
                    </p>
                </Jumbotron>
                <div className="text-center mb-4">
                    <ButtonGroup>
                        <Button href={`${process.env.PUBLIC_URL}/station/new`}>
                            <FontAwesomeIcon icon={faMap}/><br/>Neue Rally starten
                        </Button>
                        <Button href={`${process.env.PUBLIC_URL}/station`} color="primary"
                                disabled={!StationStorage.getStation()}>
                            <FontAwesomeIcon icon={faMapMarked}/><br/>Rally weitermachen
                        </Button>
                    </ButtonGroup>
                </div>
            </Container>
        );
    }
}
