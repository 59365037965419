import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import PantomimeCard from "../../../../components/PantomimeCard";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station3Location.jpg";

type stateType = {
    done: Set<number>
}

export default class StationSpecial3 extends StationSpecialAbstract<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            done: new Set<number>()
        };
        this.addToSet = this.addToSet.bind(this);
    }

    addToSet(questionId: number) {
        const done = this.state.done;
        done.add(questionId);
        this.setState({done});
        if (done.size === 6)
            this.props.onStationSucceeded();
    }

    render() {
        return (<div>
            <h2>3. Spiel & Spaß: Pantomime</h2>
            <Location name={`49°47'58.1"N 9°59'03.0"E`} image={locationImg}
                      text="Geht über den Zebrastreifen den kleinen Weg (Simon-Blenk-Weg) hinauf. Nehmt die Treppen bis ihr ganz oben am Berg ankommt. Die Straße heißt Otto-Stein-Straße. Sucht euch hier ein ruhiges Plätzchen für die Station. "/>
            Nun seid ihr an der nächsten Station angekommen.
            Hier müsst ihr die Karten mit den Begriffen pantomimisch gegenseitig darstellen.
            Schaut immer nur eine Karte an und wechselt euch nach jedem erratenen Begriff ab.
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Hausschuhe anziehen"/>
                <Button color="primary" onClick={() => this.addToSet(1)} disabled={this.state.done.has(1)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Schrank einräumen"/>
                <Button color="primary" onClick={() => this.addToSet(2)} disabled={this.state.done.has(2)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Koffer/Tasche aufmachen"/>
                <Button color="primary" onClick={() => this.addToSet(3)} disabled={this.state.done.has(3)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Koffer/Tasche auspacken"/>
                <Button color="primary" onClick={() => this.addToSet(4)} disabled={this.state.done.has(4)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Kopfkissen und Decke beziehen"/>
                <Button color="primary" onClick={() => this.addToSet(5)} disabled={this.state.done.has(5)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
            <div className="text-center mt-3">
                <PantomimeCard className="my-1" word="Betttuch über Matratze spannen"/>
                <Button color="primary" onClick={() => this.addToSet(6)} disabled={this.state.done.has(6)}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Karte Erledigt
                </Button>
            </div>
        </div>);
    }
}
