import React from "react";
import {Alert, Button, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";

type propType = {
    elements: string[],
    solution: string,
    onSelected: (correct: boolean) => void
}

type stateType = {
    value: string,
    status: "unvalidated" | "error" | "success"
}

export default class StationSpecial14Select extends React.Component<propType, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: "",
            status: "unvalidated"
        };
        this.check = this.check.bind(this);
    }

    check() {
        const correct = this.state.value === this.props.solution;
        this.setState({status: correct ? "success" : "error"});
        this.props.onSelected(correct);
    }

    render() {
        return (<div>
            <FormGroup className="text-center my-3">
                <InputGroup className="m-auto" style={{width: "20em"}}>
                    <Input type="select" value={this.state.value}
                           onChange={e => this.setState({value: e.target.value})}>
                        <option key={-1} value="" disabled>auswählen</option>
                        {this.props.elements.map((e, i) => <option key={i}>{e}</option>)}
                    </Input>
                    <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={this.check}>
                            <FontAwesomeIcon icon={faUserCheck} className="mr-2"/>Überprüfen
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <Alert color="danger" className="text-center" isOpen={this.state.status === "error"}
                   toggle={() => this.setState({status: "unvalidated"})}>
                Leider war deine Auswahl nicht korrekt!
                <br/>
                Versuche es einfach nochmal!
            </Alert>
            <Alert color="success" className="text-center" isOpen={this.state.status === "success"}>
                Das ist Richtig!
            </Alert>
        </div>);
    }
}
