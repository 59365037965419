import React from "react";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faInfoCircle, faMapSigns} from "@fortawesome/free-solid-svg-icons";
import {RouteComponentProps, withRouter} from "react-router";
import logo from "../resources/Logo.png";
import {StationStorage} from "../library/storage/Storage";

type stateType = {
    isOpen: boolean
}

class NavBar extends React.Component<RouteComponentProps, stateType> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {isOpen: false};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        return (
            <Navbar color="dark" dark expand="md" className="sticky-top box-shadow">
                <NavbarBrand href={`${process.env.PUBLIC_URL}/`} style={{padding: 0}}>
                    <img src={logo} style={{height: "2em", filter: "drop-shadow(0px 0px 10px #4444dd)"}} alt="Logo"/>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto text-center" navbar>
                        <NavItem active={!!this.props.match.path.match(/^\/home$/)}
                                 className="ml-3">
                            <NavLink href={`${process.env.PUBLIC_URL}/home`}
                                     style={{fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faHome}/> Home
                            </NavLink>
                        </NavItem>
                        <NavItem active={!!this.props.match.path.match(/^\/station(\/new)?$/)}
                                 className="ml-3">
                            <NavLink href={`${process.env.PUBLIC_URL}/station`} disabled={!StationStorage.getStation()}
                            style={{fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faMapSigns}/> Stationen
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Nav className="ml-auto text-center" navbar>
                        <NavItem>
                            <NavLink href="https://about.minis-lioba.de/" target="_blank" rel="noopener noreferrer"
                                     style={{fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faInfoCircle}/> Impressum
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default withRouter(NavBar);
