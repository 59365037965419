import React from "react";
import Container from "reactstrap/lib/Container";
import {LocationStorage, StationStorage} from "../../library/storage/Storage";
import StationenEnum from "../../types/StationenEnum";
import {Button, ButtonGroup, FormGroup, Input, Label, Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap, faMapMarked} from "@fortawesome/free-solid-svg-icons";

type stateType = {
    location?: boolean
}

export default class StationNew extends React.Component<{}, stateType> {

    static createNewStation() {
        StationStorage.setStation(StationenEnum.Station0);
        window.location.assign(`${process.env.PUBLIC_URL}/station`);
    }

    constructor(props: any) {
        super(props);
        this.state = {};
        this.setLocation = this.setLocation.bind(this);
    }

    setLocation(callback: () => void) {
        if (this.state.location !== undefined)
            LocationStorage.setShowLocation(this.state.location);
        callback();
    }

    render() {
        return (<Container className="my-2">
            <h1>Neue Rally</h1>
            <p style={{display: StationStorage.getStation() ? undefined : "none"}}>
                Willst du wirklich eine neue Rally starten?
            </p>
            <Alert color="danger" className="text-center" style={{display: StationStorage.getStation() ? undefined : "none"}}>
                <b>Achtung</b>: Wenn du eine neue Rally startest wird deine bisherige Rally inklusiv deiner aktuellen Station gelöscht!
            </Alert>
            <p style={{display: StationStorage.getStation() ? "none" : undefined}}>
                Starte eine neue Rally.
            </p>
            <FormGroup tag="fieldset">
                <legend>Wegbeschreibung</legend>
                <span className="text-muted">Die Wegbeschreibung kann auch später jederzeit in den Stationen ein- & ausgeblendet werden.</span>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="radio1" className="mr-2"
                               onClick={() => this.setState({location: false})}/>
                        Wegbeschreibung ausblenden und nur Koordinaten anzeigen (externe Karten-App ist während der Rally verfügbar)
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="radio1" className="mr-2"
                               onClick={() => this.setState({location: true})}/>
                        Wegbescheibung anzeigen (keine externe Kartenapp während der Rally vorhanden)
                    </Label>
                </FormGroup>
            </FormGroup>
            <div className="text-center">
                <ButtonGroup>
                    <Button onClick={() => this.setLocation(StationNew.createNewStation)}
                            disabled={this.state.location === undefined}>
                        <FontAwesomeIcon icon={faMap}/><br/>Neue Rally starten
                    </Button>
                    <Button color="primary" disabled={!StationStorage.getStation()}
                            onClick={() => this.setLocation(() => window.location.assign(`${process.env.PUBLIC_URL}/station`))}>
                        <FontAwesomeIcon icon={faMapMarked}/><br/>Rally fortsetzen
                    </Button>
                </ButtonGroup>
            </div>
        </Container>);
    }
}
