import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station16Location.jpg";

export default class StationSpecial16 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>16. letztes Spiel und dann Heimfahrt</h2>
            <Location name={`49°48'07.1"N 9°58'53.1"E`} image={locationImg}
            text="Nach rechts geht es nun den Berg hinunter. Gleich habt ihr es geschafft. Kennt ihr den Weg nach St. Lioba? Einfach den Berg hinunter, dann seht ihr schon den Kindergarten. Doch biegt vor dem Kindergarten nach rechts ab. Vorne an der Kreuzung geht ihr nach links in Richtung Bushaltestelle St. Lioba. Lauft weiter am Zaun entlang bis zwischen Kirche und Kindergarten ein kleiner Weg nach links abgeht. Lauft diesen hinter bis zur Garage."/>
            <p>
                Es ist kurz vor der Heimfahrt. Wir spielen noch ein letztes Spiel. Hinten an der Garage bei St. Lioba steht eine Tüte mit einem Wurfspiel. Baut es auf und stellt euch ca. 2,5 m entfernt davon auf. Jeder hat 5 Versuche, um die höchste Punktzahl zu erzielen. Viel Spaß!
            </p>
            <p>
                Desinfiziert anschließend bitte das Wurfspiel und legt es in der Tüte wieder zurück.
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Fertig
                </Button>
            </div>
        </div>);
    }
}
