import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";

export default class StationSpecial6 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>Geschafft</h2>
            <p>
                Ihr habt es geschafft! <span role="img" aria-label="party">🎉</span>
                <br/>
                Vergesst auch nicht, euren Zettel mit den Essenswünschen in den Minibriefkasten zu werfen. 
                Wir hoffen, ihr hattet eine Menge Spaß. Schön, dass ihr dabei wart. Kommt gut nach Hause! 
            </p>
        </div>);
    }
}

