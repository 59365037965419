import React from "react";
import {Alert} from "reactstrap";
import QrReader from 'react-qr-reader'

type propType = {
    onStationSucceeded: (succeded: boolean) => void,
    qr: string
}

type stateType = {
    value: string,
    status: "unscanned" | "error" | "success",
    errorTxt: string
}

export default class QRReader extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: "",
            status: "unscanned",
            errorTxt: ""
        };
        this.onScan = this.onScan.bind(this);
        this.onError = this.onError.bind(this);
    }

    onScan(data: string | null) {
        if (this.state.status === "success")
            return;
        if (data === this.props.qr) {
            this.props.onStationSucceeded(true);
            this.setState({status: "success"});
        } else {
            this.props.onStationSucceeded(false);
            this.setState({
                status: "error",
                errorTxt: "Leider wurde nicht der richtige QR-Code erkannt! Versuche es einfach nochmal!"
            });
        }
    }

    onError(err: any) {
        console.error(err.toString());
        switch (err.toString()) {
            case "NotFoundError: Requested device not found":
                this.setState({
                    status: "error",
                    errorTxt: "Es wurde leider keine Kamera bei deinem Gerät gefunden. Verwende bitte ein Geät mit Kamera!"
                });
                break;
            case "NotAllowedError: Permission denied":
                this.setState({
                    status: "error",
                    errorTxt: "Keine Berechtung zur Nutzung der Kamera! Bitte erteile uns die Kamera-Berechtigung und versuche es erneut!"
                });
                break;
            default:
                this.setState({
                    status: "error",
                    errorTxt: `Es ist ein unbekannter Fehler aufgetreten! (${err.toString()})`
                })
                break;
        }
    }

    render() {
        return (<div>
                <QrReader onScan={this.onScan} onError={this.onError}/>
            <Alert color="danger" className="text-center mt-1"
                   style={{display: this.state.status === "error" ? undefined : "none"}}
                   toggle={() => this.setState({status: "unscanned"})}>
                {this.state.errorTxt}
            </Alert>
        </div>);
    }
}
