import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station11Location.jpg";

export default class StationSpecial11 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>11. Auspowern</h2>
            <Location name={`49°48'15.5"N 9°59'43.5"E`} image={locationImg}
            text="Am Malergeschäft vorbei geht es den Berg weiter geradeaus hinauf. Dort ist schon wieder eine Schranke. Findet ihr auf der linken Seite den Spielplatz?"/>
            Nun könnt ihr euch eine Runde auf dem Spielplatz austoben.
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Ich kann nicht mehr!
                </Button>
            </div>
        </div>);
    }
}
