import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import mediation from "./Meditation.mp3";
import locationImg from "./Station12Location.jpg";

export default class StationSpecial12 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>12. Meditation</h2>
            <Location name={`49°48'18.2"N 9°59'32.4"E`} image={locationImg}
            text="Ihr seid von einer Seite gekommen, verlasst nun auf der anderen Seite den Spielplatz. Wir wollen in Richtung Lengfelder Höh. Die Straße heißt Hermann-Zürrlein-Straße. Nach dem Haus mit der Nr. 2 auf der linken Seite geht ihr nach rechts. Wenige Meter später nach dem kleinen Parkplatz biegt ihr in den Fußweg nach links ab. An der vierseitigen Kreuzung macht ihr Halt. Anschließend geht’s auch nach links weiter."/>
            <p>
                Was bei den Minis nicht fehlen darf: ein kleiner spiritueller Impuls.
                Positioniert euch dafür an der Kreuzung so, dass euch Fußgänger nicht stören und du den Blick noch in die Ferne richten kannst.
                Hört euch dann die Audio-Aufnahme an und genießt die Aussicht.
            </p>
            <audio controls style={{width: "100%"}} controlsList="nodownload" preload="auto" src={mediation}
                   onEnded={() => this.props.onStationSucceeded()}/>
        </div>);
    }
}
