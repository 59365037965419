import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station17Location.jpg";

export default class StationSpecial16 extends StationSpecialAbstract {
    render() {
        return (<div>
            <h2>17. Gruppenfoto</h2>
            <Location name={`49°48'07.3"N 9°58'54.7"E`} image={locationImg}
            text="Nun geht es zurück nach St. Lioba. Die letzte Station ist vor unseren Mini-Räumen, die Treppen hinunter."/>
            <p>
                Wir sind wieder an St. Lioba angekommen. Lasst uns doch noch ein Gruppenfoto machen. Malt euch dafür auf das Tuch, dass an unserer Eingangstür hängt. Die Stifte stehen neben dran.  Bitte desinfiziert die Stifte, die ihr benutzt habt, und stellt sie wieder in das Glas.
            </p>
            <p>
                Macht bitte auch ein Foto von dem kompletten Bild und schicke es an <a
                href="mailto:rally@minis-lioba.de?subject=Station%2017%3A%20Gruppenfoto%20%7C%20Mini-Rally&body=Bitte%20Foto%20einf%C3%BCgen!"
            >rally@minis-lioba.de</a>!
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Fertig
                </Button>
            </div>
        </div>);
    }
}
