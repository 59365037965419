import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import schnarchenMirjam from "./SchnarchenMirjam.mp3";
import schnarchenFelix from "./SchnarchenFelix.mp3";
import schnarchenNico from "./SchnarchenNico.mp3";
import StationSpecial14Select from "./StationSpecial14Select";
import locationImg from "./Station14Location.jpg";

const PERSONS = ["Mirjam", "Hannah D.", "Hanna S.", "Nico", "Thomas", "Fabian", "Moritz", "Felix", "Jonathan"].sort();

export default class StationSpecial14 extends StationSpecialAbstract {
    private correct = new Set<number>();

    constructor(props: any) {
        super(props);
        this.check = this.check.bind(this);
    }

    check(id: number, correct: boolean) {
        if (correct)
            this.correct.add(id);
        else
            this.correct.delete(id);

        this.props.onStationSucceeded(this.correct.size === 3);
    }

    render() {
        return (<div>
            <h2>14. Ab ins Bett: Wer schläft hier?</h2>
            <Location name={`49°48'19.8"N 9°59'01.8"E`} image={locationImg}
            text="Lauft die Birkenstraße entlang, bis ihr auf die Flürleinstraße kommt. Biegt in die Flürleinstraße nach links ab und folgt der Straße bis ihr an den Strommast für die nächste Station gelangt."/>
            <p>
                Der Bunte Abend neigt sich dem Ende zu. Es ist Schlafenszeit. Die Leiter schlafen schon. Könnt ihr anhand der Schlafgeräusche erkennen, um welchen Leiter es sich handelt? Ordnet zu!
                Ihr müsst aber ganz leise sein und das Handy nah ans Ohr halten, damit ihr die Leiter hören könnt!
            </p>
            <h3>Person 1</h3>
            <audio controls style={{width: "100%"}} controlsList="nodownload" preload="auto" src={schnarchenMirjam}/>
            <StationSpecial14Select elements={PERSONS} solution="Mirjam"
                                    onSelected={correct => this.check(1, correct)}/>
            <h3>Person 2</h3>
            <audio controls style={{width: "100%"}} controlsList="nodownload" preload="auto" src={schnarchenFelix}/>
            <StationSpecial14Select elements={PERSONS} solution="Felix"
                                    onSelected={correct => this.check(2, correct)}/>
            <h3>Person 3</h3>
            <audio controls style={{width: "100%"}} controlsList="nodownload" preload="auto" src={schnarchenNico}/>
            <StationSpecial14Select elements={PERSONS} solution="Nico"
                                    onSelected={correct => this.check(3, correct)}/>
        </div>);
    }
}
