import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station4Location.jpg";

export default class StationSpecial4 extends StationSpecialAbstract {

    render() {
        return (<div>
            <h2>4. Abendessen!</h2>
            <Location name={`49°47'54.8"N 9°59'01.6"E`} image={locationImg}
                      text="Kennt ihr den Pavillon am Spielplatz? Dort ist die nächste Station. Lauft dort hin! Wenn ihr ihn nicht kennt: Ihr seid den ganzen Berg mehr oder weniger gerade hinaufgelaufen. Lauft nun den kleinen Weg gerade weiter auf die große Wiese zu. Falls ihr euch unsicher seid: Der Weg geht zwischen dem Haus Nr. 23 auf der linken Seite und dem Haus Nr. 25 auf der rechten Seite durch. Auf der rechten Seite findet ihr – hinter Gebüsch versteckt – einen Spielplatz. Hinter dem Spielplatz steht der Pavillon."/>
            <p>
                Perfekt, ihr habt schon einige Aufgaben erfüllt.
            </p>
            <p>
                An dieser Station ist es sehr entspannt.
                Ihr findet in eurem Tütchen einen einzelnen, leeren, weißen Zettel. Auf diesen dürft ihr eure Wünsche,
                was es zum Abendessen (kalt/warm) am nächsten Winterwochenende (voraussichtlich Januar 2022) geben soll.
            </p>
            <p>
                Diesen Zettel bewahrt ihr gut auf und werft ihn später in den Briefkasten am Miniraum.
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Zettel ausgefüllt!
                </Button>
            </div>
        </div>);
    }
}
