import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Button} from "reactstrap";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import locationImg from "./Station5Location.jpg";

export default class StationSpecial5 extends StationSpecialAbstract {

    render() {
        return (<div>
            <h2>5. Evolution!</h2>
            <Location name={`49°47'57.6"N 9°59'06.6"E`} image={locationImg}
            text="Über den Spielplatz zurück, gelangt ihr wieder auf den Weg. Vor euch liegt die große Hundewiese. Ihr wollt auf die andere Seite der Hundewiese. Nehmt dafür den Weg auf der linken Seite. Dort befindet sich eine Bank. Macht an dieser Bank Halt und erledigt die nächste Station."/>
            <p>
                Nach dem Abendessen spielen wir noch ein Spiel. Vielleicht kennt ihr es bereits von früheren Winterwochenenden. Es heißt „Evolution“.  
            </p>
            <h3>Speilanleitung</h3>
            <p>
            Ihr spielt gegeneinander „Schere, Stein, Papier“. Der Gewinner steigt eine Evolutionsstufe auf. Der Verlierer steigt eine Evolutionsstufe ab. Bei einem Unentschieden bleibt ihr auf eurer Evolutionsstufe stehen. 
            Die Evolutionsstufen: Zelle – Fisch – Bär – Mensch 
            Jeder Spieler beginnt als Zelle. Wer als erstes die Evolutionsstufe Mensch erreicht, hat gewonnen. 
            Spielt ihr das Spiel mit mehr als zwei Personen, geht das Spiel genauso. Gibt es keinen eindeutigen Sieger, bleibt jeder auf seiner Evolutionsstufe stehen.
            </p>
            <div className="text-center">
                <Button onClick={() => this.props.onStationSucceeded()} color="primary">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>Fertig gespielt!
                </Button>
            </div>
        </div>);
    }
}
