import React from "react";
import StationSpecialAbstract from "../../StationSpecialAbstract";
import Location from "../../../../components/Location";
import {Alert, Button, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checker from "../../../../library/Checker";
import locationImg from "./Station10Location.jpg";

type stateType = {
    value: string,
    showError: boolean
}

export default class StationSpecial10 extends StationSpecialAbstract<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: "",
            showError: false
        };
        this.check = this.check.bind(this);
    }

    check() {
        if (this.state.value === "Heckelmann") {
            this.props.onStationSucceeded();
            this.setState({showError: false});
        } else {
            this.props.onStationSucceeded(false);
            this.setState({showError: true});
        }
    }

    render() {
        return (<div>
            <h2>10. Rally-Frage</h2>
            <Location name={`49°48'12.1"N 9°59'46.6"E`} image={locationImg}
            text="Nun geht’s nach rechts weiter. Von weitem seht ihr eine Schranke. Dort angekommen lauft ihr nach links den Berg hinauf. An der nächsten Kreuzung macht ihr die Station. Danach geht es weiter geradeaus."/>
            Es ist Samstagvormittag – Zeit für eine Rallye: Ihr steht vor einem Malergeschäft. Wie heißt der Inhaber mit Nachnamen? 
            <FormGroup className="text-center my-3">
                <InputGroup className="m-auto" style={{width: "17em"}}>
                    <Input type="text" value={this.state.value} onKeyUp={e => Checker.isEnter(e, this.check)}
                           onChange={e => this.setState({value: e.target.value})}/>
                    <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={this.check}>
                            <FontAwesomeIcon icon={faUserCheck} className="mr-2"/>Überprüfen
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <Alert color="danger" className="text-center" style={{display: this.state.showError ? undefined : "none"}}
            toggle={() => this.setState({showError: false})}>
                Die Antwort ist leider nicht korrekt, versuche es erneut!
                <br/>
                Der Nachname findet sich im Namen des Maler und Verputzer Geschäfts!
            </Alert>
        </div>);
    }
}
